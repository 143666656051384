<template>
  <div class="access">

    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <el-row>
          <el-col :span="12">
            <iframe title="のぞみクリニック築地 GoogleMap アドレス" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1620.7302022458769!2d139.77382708041415!3d35.66566389811144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601889623b873a13%3A0x1c21f5e80c1e7f9d!2z44CSMTA0LTAwNDUg5p2x5Lqs6YO95Lit5aSu5Yy656-J5Zyw77yX5LiB55uu77yR77yR4oiS77yVIOS4remKgOODmeODq-evieWcsOODnuODs-OCt-OCquODsyAy6ZqO!5e0!3m2!1sja!2sjp!4v1633341603803!5m2!1sja!2sjp" width="100%" height="480" style="border:none" allowfullscreen="" loading="lazy"></iframe>
          </el-col>
          <el-col :span="11">
            <div class="schedule">
              <div class="name">
                <div class="name-tenyukai">医療法人社団 天裕会</div>
                <div class="name-nozomi">のぞみクリニック築地</div>
              </div>
              <div class="address">
                <div>〒104-0045</div>
                <div>東京都中央区築地7-11-5 中銀ベル築地マンシオン2階</div>
                <div>東京メトロ日比谷線 築地駅1番、2番出口より徒歩4分</div>
              </div>
              <div class="tel">
                <div><img src="../../assets/icon/icon_tel_hover_28x28@2x.png" alt="" width="28" height="28"></div>
                <div class="tel-number">03-6260-6008</div>
              </div>
              <div class="table">
                <table>
                  <tr>
                    <td>診療時間</td>
                    <td>月</td>
                    <td>火</td>
                    <td>水</td>
                    <td>木</td>
                    <td>金</td>
                    <td>土</td>
                    <td>日</td>
                  </tr>
                  <tr>
                    <td>10:00 ～ 13:30</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                    <td>○</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                  </tr>
                  <tr>
                    <td>14:30 ～ 19:00</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                    <td>○</td>
                    <td>○</td>
                    <td>○</td>
                    <td>休</td>
                  </tr>
                </table>
              </div>
              <div class="notice">※ 受付は３０分前までとなります。</div>
              <div class="notice">※ 休診日：水曜・日曜・祝日・年末年始</div>
              <div class="notice">※ 中国語対応</div>


              <div class="info">
                <div class="btn-online" @click="goTo('/department/telemedicine')">
                  <div class="btn-icon"></div>
                  <div class="btn-text">
                    <div>WEB予約</div>
                    <div class="sub-text">オンライン診療はこちらから</div>
                  </div>
                </div>
                <div class="btn-mail" @click="goTo('/sending')">
                  <div class="btn-icon"></div>
                  <div class="btn-text">お問い合わせ</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: "HomeAccess",
  methods: {
    goTo(page) {
      this.$router.push(`${page}`)
    }
  }
}
</script>

<style scoped>
/*病院情報*/
.access {
  margin: 60px 0;
}
.schedule {
  width: 100%;
  margin-left: 30px;
}
.schedule .name-tenyukai {
  color: #9E9E9E;
  font-size: 14px;
  font-weight: bold;
}
.schedule .name-nozomi {
  color: #62B6E2;
  font-size: 20px;
  font-weight: bold;
}
.schedule .address {
  font-size: 14px;
  margin-top: 14px;
}
.schedule .tel {
  align-items: center;
  display: flex;
  margin-top: 14px;
}
.schedule .tel .tel-number {
  font-size: 24px;
  font-weight: bold;
  margin-left: 14px;
}
/*診療時間*/
.schedule .table table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
}
.schedule .table table tr:first-child {
  background-color: #f0f0f0;
}
.schedule .table table td {
  border: 1px solid #cccccc;
  text-align: center;
  padding: 16px;
}
.schedule .notice {
  font-size: 12px;
  margin-top: 12px;
  margin-left: 12px;
}
/*予約・お問い合わせ*/
.info {
  margin-top: 30px;
  display: flex;
}
.info .btn-online {
  align-items: center;
  background-color: #62B6E2;
  color: #ffffff;
  border: 2px solid #62B6E2;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  transition: 150ms;
}
.info .btn-online .btn-icon{
  height: 36px;
  width: 36px;
  background-image: url("../../assets/icon/icon_calendar_36x36@2x.png");
  background-size: 100%;
  transition: 150ms;
}
.info .btn-online:hover {
  background-color: #FFFFFF;
  color: #62B6E2;
}
.info .btn-online:hover .btn-icon{
  background-image: url("../../assets/icon/icon_calendar_hover_36x36@2x.png");
}
.info .btn-mail {
  align-items: center;
  background-color: #62B6E2;
  color: #ffffff;
  border: 2px solid #62B6E2;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  padding: 12px 20px;
  margin-left: 20px;
  transition: 150ms;
}
.info .btn-mail .btn-icon{
  height: 36px;
  width: 36px;
  background-image: url("../../assets/icon/icon_mail_36x36@2x.png");
  background-size: 100%;
  transition: 150ms;
}
.info .btn-mail:hover {
  background-color: #ffffff;
  color: #62B6E2;
}
.info .btn-mail:hover .btn-icon{
  background-image: url("../../assets/icon/icon_mail_hover_36x36@2x.png");
}
.info .btn-text {
  font-size: 16px;
  margin-left: 10px;
}
.info .btn-text .sub-text {
  font-size: 12px;
  margin-top: 4px;
}
</style>
